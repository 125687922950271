.chat-wrapper {
  max-height: 500px;
  min-height: 500px;
  overflow-y: auto;
  white-space: nowrap;
}

.chat-wrapper::-webkit-scrollbar {
  display: none;
}

.chat-wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.chat-wrapper::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 5px;
}

.chat-list {
  padding: 0;
  font-size: .8rem;
  white-space: normal;
}

.chat-list li {
  margin-bottom: 10px;
  overflow: auto;
  color: #ffffff;
  white-space: normal;
  word-break: break-word;
}

.chat-list .chat-img {
  float: left;
  width: 48px;
}

.chat-list .chat-img img {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  width: 100%;
}

.chat-list .chat-message {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  background: #5a99ee;
  display: inline-block;
  padding: 10px 20px;
  position: relative;
}

.chat-list .custom-button {
  margin-right: 70px;
}

.chat-list .chat-message:before {
  content: "";
  position: absolute;
  top: 15px;
  width: 0;
  height: 0;
}

.chat-list .chat-message h5 {
  margin: 0 0 5px 0;
  font-weight: 600;
  line-height: 100%;
  font-size: .9rem;
}

.chat-list .chat-message p {
  line-height: 18px;
  margin: 0;
  padding: 0;
}

.chat-list .chat-body {
  margin-left: 20px;
  float: left;
  width: 70%;
  white-space: normal;
}

.chat-list .in .chat-message:before {
  left: -12px;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #5a99ee;
  white-space: normal;
}

.chat-list .out .chat-img {
  float: right;
}

.chat-list .out .chat-body {
  float: right;
  margin-right: 20px;
  text-align: right;
  white-space: normal;
}

.chat-list .out .chat-message {
  background: #fc6d4c;
  white-space: normal;
}

.chat-list .out .chat-message:before {
  right: -12px;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #fc6d4c;
  white-space: normal;
}

.card .card-header:first-child {
  -webkit-border-radius: 0.3rem 0.3rem 0 0;
  -moz-border-radius: 0.3rem 0.3rem 0 0;
  border-radius: 0.3rem 0.3rem 0 0;
}

.card .card-header {
  background: #17202b;
  border: 0;
  font-size: 1rem;
  padding: .65rem 1rem;
  position: relative;
  font-weight: 600;
  color: #ffffff;
}

.content {
  padding-left: 0px;
  margin-left: 0px;
  margin-top: 40px;
}

.chat-list .chat-message strong {
  color: whitesmoke;
}
