@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
/* Colors */
.green {
    color: #86BB71;
  }
  .orange {
    color: #E38968;
  }
  
  /* Body styles */
  body {
    background: #C5DDEB;
    font: 14px/20px "Lato", Arial, sans-serif;
    color: white;
    background-color: #ffec63;
    background-image: linear-gradient(
        45deg,
        #ffd966 25%,
        transparent 25%,
        transparent 75%,
        #ffd966 75%,
        #ffd966
      ),
      linear-gradient(
        -45deg,
        #ffd966 25%,
        transparent 25%,
        transparent 75%,
        #ffd966 75%,
        #ffd966
      );
    background-size: 60px 60px;
    background-position: 0 0;
    animation: slide 4s infinite linear;
  }
  
  /* Container styles */
  .container-people {
    margin: 0 auto;
    width: 300px;
    background: #444753;
    border-radius: 5px;
  }
  
  /* Scroll Bar */
  .scroll {
    max-height: 500px; /* Set the desired height of the chatbox */
    overflow-y: auto;
  }

  .scroll::-webkit-scrollbar {
    width: 8px;
  }
  
  .scroll::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .scroll::-webkit-scrollbar-thumb {
    background-color: #888888;
    border-radius: 5px;
  }

  /* People list styles */
  .people-list .search {
    padding: 20px;
    padding-bottom: 5px;
  }
  
  .people-list input {
    border-radius: 3px;
    border: none;
    padding: 14px;
    color: white;
    background: #6A6C75;
    width: 90%;
    font-size: 14px;
  }
  
  .people-list .fa-search {
    position: relative;
    left: -25px;
  }
  
  .people-list ul {
    padding: 10px;
  }
  
  .people-list ul li {
    padding-bottom: 20px;
  }

  .people-list ul li:hover {
    background-color: #555862;
  }

  .people-list img {
    float: left;
  }
  
  .people-list .about {
    float: left;
    margin-top: 8px;
    padding-left: 8px;
  }
  
  .people-list .status {
    color: #92959E;
  }
  
  /* Online/offline status */
  .online,
  .offline {
    margin-right: 3px;
    font-size: 10px;
  }
  
  .online {
    color: #86BB71;
  }
  
  .offline {
    color: #E38968;
  }
  
  /* Clearfix */
  .clearfix {
    cursor: pointer;
  }
  .clearfix:hover {
    background-color: #555862;
    border-radius: 5px;
  }
  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  .title {
    --primary-360-hsl: 214 8.1% 61.2%;
    color: hsl(var(--primary-360-hsl));
  }